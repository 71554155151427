import http from "../../helpers/http-common";

class TopicDataService {

    get(id) {
        return http.get(`/topics/${id}`);
    }

    create(data) {
        return http.post("/topics", data);
    }

    update(id, data) {
        return http.put(`/topics/${id}`, data);
    }

    delete(id) {
        return http.delete(`/topics/${id}`);
    }

    deleteAll() {
        return http.delete('/topics');
    }

    getAll(programId) {
        return http.get(`/topics?programId=${programId}`);
    }

    findByName(name) {
        return http.get(`/topics?title=${name}`);
    }
}

export default new TopicDataService();
