<template>
    <div class="topic-tree">
        <div class="label-wrapper text-truncate">
            <div :style="indent" :class="labelClasses">
                <span class="label-caret" @click="toggleChildren">
                    <b-icon-caret-down-fill v-if="topics && showChildren"/>
                    <b-icon-caret-right-fill v-if="topics && !showChildren"/>
                </span>

                <span class="label-index">{{ composeOrder(topic.order) }}</span>
                &nbsp;  <span class="label-title" @click="setActiveTopic(topic)">{{ label }}</span>
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;

            </div>
        </div>
        <div v-if="showChildren">
            <topic-tree-view
                v-for="topic in topics"
                :topic="topic"
                :key="topic.id"
                :topics="topic.children"
                :label="topic.title"
                :depth="depth + 1"
                :parentOrder="topic.order"
            />
        </div>

    </div>
</template>

<script>

export default {
    name: "topic-tree-view",
    props: [ 'topics', 'label','topic', 'depth', 'parentOrder' ],
    data() {
        return {
            showChildren: true
        }
    },
    computed: {
        iconClasses() {
            return {
                'chevron-right': !this.showChildren,
                'chevron-down': this.showChildren
            }
        },
        labelClasses() {
            return { 'has-children': this.topics, 'text-muted': !this.topic.mandatory }
        },
        indent() {
            return { transform: `translate(${this.depth * 25}px)` }
        }
    },
    methods: {
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        setActiveTopic(topic) {
            this.$parent.setActiveTopic(topic);
        },
        composeOrder(topicOrder) {
            let order = '';
            if (this.parentOrder) {
                order += this.parentOrder+'.';
            }
            order += topicOrder
            return order
        },
    }
}
</script>

<style scoped>
.topic-tree .label-wrapper {

    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #dee2e6;
}
.topic-tree .has-children {
    cursor: pointer;
}
.label-title:hover {
    cursor: pointer;
}
.topic-tree .label-index {
    font-weight: bold;
    padding-right: 10px;
}
.topic-tree .label-caret {
    display: inline-block;
    width: 25px;
}
</style>



