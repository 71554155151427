<template>
    <div>
        <div class="top-content container">
            <div class="row">
                <div class="col-md-6">
                    <h4>Argomenti</h4>
                </div>
                <div class="col-md-6 text-right">
                    <button class="btn btn-primary btn-add" @click="currentId = 0; currentTopic = null">
                        <b-icon icon="plus"/> Aggiungi Argomenti
                    </button>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <div><b-icon icon="sort-down-alt"/> &nbsp; <b>Titolo</b></div>
                    <hr>
                    <topic-tree-view
                        v-for="topic in topics"
                        :topic="topic"
                        :key="topic.id"
                        :topics="topic.children"
                        :label="topic.title"
                        :depth="0"
                    />

                </div>
            </div>

            <div class="editor" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <topic-edit :topic-id="currentTopic.id" :key="currentTopic.id" :topics="topics"
                                  v-on:updated-topic="refreshList()"
                                  v-on:deleted-topic="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <topic-create :program-id="this.programId" :topics="topics"
                        v-on:created-topic="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import TopicDataService from "./TopicDataService";
import TopicEdit from "./TopicEdit";
import TopicCreate from "./TopicCreate";
import SearchBar from "@/components/layout/SearchBar";
import TopicTreeView from "@/components/topic/TopicTreeView";

export default {
    name: "topics-index",
    components: {TopicEdit, TopicCreate, TopicTreeView},
    data() {
        return {
            topics: [],
            currentTopic: null,
            currentId: -1,
            name: ""
        };
    },
    props: ['programId'],
    methods: {
        retrieveTopics() {
            TopicDataService.getAll(this.programId)
                .then(response => {
                    this.topics = response.data.rows;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrieveTopics();
            this.currentTopic = null;
            this.currentId = -1;
        },

        refreshCurrentTopic(topic) {
            this.topics.forEach(function (item) {
                if (item.id == topic.id) {
                    item.title = topic.title;
                    item.order = topic.order;
                }
            });
            this.closeEditor();
            //this.currentTopic = topic;
            //this.currentId = topic.id;
        },

        setActiveTopic(topic) {
            this.currentTopic = topic;
            this.currentId = topic.id;
        },

        removeAllTopics() {
            TopicDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentTopic = null;
            this.currentId = -1;
            TopicDataService.findByName(name)
                .then(response => {
                    this.topics = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentTopic = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrieveTopics();
    }
};
</script>

<style scoped>
.editor.opened {
    top: 20vh;
}
</style>
