import http from "../../helpers/http-common";

class ProgramDataService {
    getAll(type) {
        if(type) {
            return http.get(`/programs?type=${type}`);
        } else {
            return http.get("/programs");
        }
    }

    get(id) {
        return http.get(`/programs/${id}`);
    }

    create(data) {
        return http.post("/programs", data);
    }

    update(id, data) {
        return http.put(`/programs/${id}`, data);
    }

    archive(id) {
        return http.get(`/programs/archive/${id}`);
    }

    delete(id) {
        return http.delete(`/programs/${id}`);
    }

    deleteAll() {
        return http.delete('/programs');
    }

    findByName(name) {
        return http.get(`/programs?name=${name}`);
    }
}

export default new ProgramDataService();
