<template>
    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h4>Programmi</h4>
            </div>

            <div class="col-md-6">
                <search-bar @search-name="searchName($event)"/>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content ">
            <button class="btn btn-primary btn-add" @click="currentId = 0; currentProgram = null">
                <b-icon icon="plus"/> Aggiungi Programma
            </button>
        </div>

        <div class="content">
            <div class="index index-30" :class="{ 'opened': (currentId == -1) }">
                <div class="card">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col" width="40">#</th>
                            <th scope="col" width="50">Tipo</th>
                            <th scope="col">Nome</th>
                            <th scope="col" class="text-center opt">Argomenti</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="{ 'table-primary': program.id == currentId }"
                            v-for="program in programs"
                            :key="program.id"
                            @click="setActiveProgram(program)"
                        >
                            <td>{{ program.id }}</td>
                            <td>{{ types.find(item => item.value == program.type).name }}</td>
                            <td>{{ program.name }}  <b-badge v-if="program.edulab == 1">EduLab</b-badge> </td>
                            <td class="text-center opt">{{ program.topics.length }}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="editor editor-70" :class="{ 'opened': (currentId >= 0) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <div v-if="currentId > 0">
                    <program-edit :program-id="currentProgram.id" :key="currentProgram.id"
                                  v-on:updated-program="refreshCurrentProgram($event)"
                                  v-on:deleted-program="refreshList()"
                                  v-on:closed-editor="closeEditor()"/>
                </div>
                <div v-else-if="currentId == 0">
                    <program-create
                        v-on:created-program="refreshList()"
                        v-on:closed-editor="closeEditor()"/>
                </div>
            </div>

        </div>




    </div>

</template>

<script>
import ProgramDataService from "./ProgramDataService";
import ProgramEdit from "./ProgramEdit";
import ProgramCreate from "./ProgramCreate";
import SearchBar from "@/components/layout/SearchBar";

export default {
    name: "programs-index",
    components: {ProgramEdit, ProgramCreate, SearchBar},
    data() {
        return {
            programs: [],
            types: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            currentProgram: null,
            currentId: -1,
        };
    },
    methods: {
        retrievePrograms() {
            ProgramDataService.getAll()
                .then(response => {
                    this.programs = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        refreshList() {
            this.retrievePrograms();
            this.currentProgram = null;
            this.currentId = -1;
        },

        refreshCurrentProgram(program) {
            this.programs.forEach(function (item) {
                if (item.id == program.id) {
                    item.name = program.name;
                }
            });
            this.closeEditor();
            //this.currentProgram = program;
            //this.currentId = program.id;
        },

        setActiveProgram(program) {
            this.currentProgram = program;
            this.currentId = program.id;
        },

        removeAllPrograms() {
            ProgramDataService.deleteAll()
                .then(response => {
                    this.refreshList();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        searchName(name) {
            this.currentProgram = null;
            this.currentId = -1;
            ProgramDataService.findByName(name)
                .then(response => {
                    this.programs = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        closeEditor() {
            this.currentProgram = null;
            this.currentId = -1;
        }
    },
    mounted() {
        this.retrievePrograms();
    }
};
</script>
