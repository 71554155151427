<template>
    <div v-if="currentTopic" class="card">
        <b-form @submit="updateTopic">
            <b-form-group id="title" label="Titolo" label-for="title" description="">
                <b-form-input v-model="currentTopic.title" placeholder=""></b-form-input>
            </b-form-group>

            <div class="row">
                <div class="col-sm-3">
                    <b-form-group id="order" label="Ordine" label-for="order" description="">
                        <b-form-input v-model="currentTopic.order" placeholder=""></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-sm-9">
                    <b-form-group id="parentId" label="Figlio di" label-for="parentId" description="">
                        <b-form-select v-model="currentTopic.parentId">
                            <option value="0">- Scegli un padre -</option>
                            <option v-for="topic in orderedTopics" v-bind:value="topic.id" :key="topic.id">
                                {{ '\xA0\xA0\xA0\xA0'.repeat(topic.hierarchyLevel-1)}}
                                {{ topic.fullOrder }}
                                {{ topic.title }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                </div>
            </div>

            <b-form-group id="mandatory" label="" label-for="mandatory" description="">
                <b-form-checkbox name="mandatory" value="true" v-model="currentTopic.mandatory">Obbligatorio</b-form-checkbox>
            </b-form-group>

            <b-form-group id="idSim" label="ID dell'argomento per simulatore" label-for="idSim" description="">
                <b-form-input v-model="currentTopic.idSim"
                              placeholder=""></b-form-input>
            </b-form-group>

        </b-form>

        <div class="action-bar row">
            <div class="col-sm-8">
                <button class="btn btn-success " @click="updateTopic" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
            </div>
            <div class="col-sm-4 text-right">
                <b-button variant="outline-danger" @click="deleteTopic" :disabled="this.$store.state.loading"> <b-icon icon="trash"/></b-button>
            </div>
        </div>
        <div>
            <br>
            <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
            <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
        </div>
    </div>

    <div v-else>
        <br/>
        <p>{{ (errorMsg) ? errorMsg : 'Please click on a Topic...' }}</p>
    </div>
</template>

<script>
import TopicDataService from "./TopicDataService";

export default {
    name: "topic-edit",
    data() {
        return {
            currentTopic: null,
            orderedTopics: [],
            message: '',
            errorMsg: ''
        };
    },
    props: ["topicId","topics"],
    methods: {
        getTopic(id) {
            TopicDataService.get(id)
                .then(response => {
                    this.currentTopic = response.data;
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateTopic() {
            TopicDataService.update(this.currentTopic.id, this.currentTopic)
                .then(response => {
                    this.message = 'The topic was updated successfully!';
                    this.$emit('updated-topic', this.currentTopic);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        deleteTopic() {
            if(confirm(`Vuoi cancellare l'argomento ${this.currentTopic.title}?`)) {
                TopicDataService.delete(this.currentTopic.id)
                    .then(response => {
                        this.$emit('deleted-topic');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        this.getTopic(this.topicId);
        function createTopicListArray(topics, resultArray, parentOrder){
            for (let topic of topics) {
                let fullOrder = (parentOrder > 0) ? parentOrder+'.'+topic.order : topic.order;
                resultArray.push({
                    id: topic.id,
                    title: topic.title,
                    order: topic.order,
                    fullOrder: fullOrder,
                    hierarchyLevel: topic.hierarchyLevel,
                });
                if (topic.children) createTopicListArray(topic.children, resultArray, fullOrder);
                else return;
            }
        }
        createTopicListArray(this.topics, this.orderedTopics, 0);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
