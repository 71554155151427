<template>
    <div>
        <div v-if="currentProgram" class="card">
            <b-form @submit="updateProgram">
                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="currentProgram.name" placeholder=""></b-form-input>
                </b-form-group>
            </b-form>

            <b-form-group id="type" label="Tipo" label-for="type" description="">
                <b-form-select v-model="currentProgram.type">
                    <option value="0">- Scegli un tipo -</option>
                    <option v-for="item in types" v-bind:value="item.value" :key="item.value">
                        {{ item.name }}
                    </option>
                </b-form-select>
            </b-form-group>

            <b-form-group id="drivingLicenseId" label="Patente" label-for="drivingLicenseId" description="">
                <b-form-select v-model="currentProgram.drivingLicenseId">
                    <option value="0">- Scegli la patente associata -</option>
                    <option v-for="item in drivingLicenses" v-bind:value="item.id" :key="item.id">
                        {{ item.name }}
                    </option>
                </b-form-select>
            </b-form-group>

            <b-form-group id="idSim" label="ID del programma per simulatore" label-for="idSim" description="" v-if="(currentProgram.type && currentProgram.type == 'practice')">
                <b-form-input v-model="currentProgram.idSim"
                              placeholder=""></b-form-input>
            </b-form-group>

            Collegato ad Edulab
            <toggle-button v-model="currentProgram.edulab" :height="25"/>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updateProgram" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">
                    <b-button variant="outline-danger" @click="archiveProgram" :disabled="this.$store.state.loading"> <b-icon icon="archive"/></b-button>
                </div>
            </div>

            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

        <div v-if="currentProgram" class="">
            <argument-index :program-id="this.currentProgram.id"/>
        </div>

        <div v-else>
            <br/>
            <p>{{ (errorMsg) ? errorMsg : 'Please click on a Program...' }}</p>
        </div>
    </div>
</template>

<script>
import ProgramDataService from "./ProgramDataService";
import ArgumentIndex from "../topic/TopicIndex";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";

export default {
    name: "program-edit",
    components: {ArgumentIndex},
    data() {
        return {
            currentProgram: null,
            types: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            drivingLicenses: [],
            message: '',
            errorMsg: ''
        };
    },
    props: ["programId"],
    methods: {
        getProgram(id) {
            ProgramDataService.get(id)
                .then(response => {
                    this.currentProgram = response.data;
                    //this.currentProgram.email = response.data.user.email;
                    this.currentProgram.active = (this.currentProgram.active === 1);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        updateProgram() {
            ProgramDataService.update(this.currentProgram.id, this.currentProgram)
                .then(response => {
                    this.message = 'The program was updated successfully!';
                    this.$emit('updated-program', this.currentProgram);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        archiveProgram() {
            if(confirm(`Vuoi archiviare il programma ${this.currentProgram.name}?`)) {
                ProgramDataService.archive(this.currentProgram.id)
                    .then(response => {
                        this.$emit('deleted-program');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        deleteProgram() {
            if(confirm(`Vuoi cancellare il programma ${this.currentProgram.name}?`)) {
                ProgramDataService.delete(this.currentProgram.id)
                    .then(response => {
                        this.$emit('deleted-program');
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

        },

        closeEditor() {
            this.$emit('closed-editor');
        }
    },
    mounted() {
        this.message = '';
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
        this.getProgram(this.programId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
