<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="saveProgram">

                <b-form-group id="name" label="Nome" label-for="name" description="">
                    <b-form-input v-model="program.name"
                                  placeholder=""
                                  :state="validateState('name')"
                                  aria-describedby="name-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="name-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="type" label="Tipo" label-for="type" description="">
                    <b-form-select v-model="program.type">
                        <option value="0">- Scegli un tipo -</option>
                        <option v-for="item in types" v-bind:value="item.value" :key="item.value">
                            {{ item.name }}
                        </option>
                    </b-form-select>
                </b-form-group>

                <b-form-group id="drivingLicenseId" label="Patente" label-for="drivingLicenseId" description="">
                    <b-form-select v-model="program.drivingLicenseId">
                        <option value="0">- Scegli la patente associata -</option>
                        <option v-for="item in drivingLicenses" v-bind:value="item.id" :key="item.id">
                            {{ item.name }}
                        </option>
                    </b-form-select>
                </b-form-group>


                <b-form-group id="idSim" label="ID del programma per simulatore" label-for="idSim" description="" v-if="(program.type && program.type == 'practice')">
                    <b-form-input v-model="program.idSim"
                                  placeholder=""></b-form-input>
                </b-form-group>

                Collegato ad Edulab
                <toggle-button v-model="program.edulab" :height="25"/>

            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="saveProgram" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>
                </div>
                <div class="col-sm-4 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import ProgramDataService from "./ProgramDataService";
import DrivingLicenseDataService from "@/components/driving_license/DrivingLicenseDataService";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "program-create",
    data() {
        return {
            program: {
                id: null,
                idSim: null,
                name: '',
                type: null,
                edulab: null,
                drivingLicenseId: null
            },
            types: [{name: 'Teoria', value: 'lesson'}, {name: 'Guida', value: 'practice'}],
            drivingLicenses: [],
            submitted: false,
            message: '',
            errorMsg: '',
        };
    },
    validations: {
        program: {
            name: {
                required,
            },
            type: {
                required,
            }
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.program[name];
            return $dirty ? !$error : null;
        },
        saveProgram() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                ProgramDataService.create(this.program)
                    .then(response => {
                        this.program.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-program');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
    },
    mounted() {
        DrivingLicenseDataService.getAll()
            .then(response => {
                this.drivingLicenses = response.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
