<template>
    <div class="card">
        <div v-if="!submitted">
            <b-form @submit.stop.prevent="saveTopic">
                <b-form-group id="title" label="Titolo" label-for="title" description="">
                    <b-form-input v-model="topic.title"
                                  placeholder=""
                                  :state="validateState('title')"
                                  aria-describedby="title-live-feedback"></b-form-input>
                    <b-form-invalid-feedback
                        id="title-live-feedback"
                    >Questo campo è obbligatorio.</b-form-invalid-feedback>
                </b-form-group>

                <div class="row">
                    <div class="col-sm-3">
                        <b-form-group id="order" label="Ordine" label-for="order" description="">
                            <b-form-input v-model="topic.order"
                                          placeholder=""
                                          :state="validateState('order')"
                                          aria-describedby="order-live-feedback"></b-form-input>
                            <b-form-invalid-feedback
                                id="order-live-feedback"
                            >Questo campo è obbligatorio.</b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                    <div class="col-sm-9">
                        <b-form-group id="parentId" label="Figlio di" label-for="parentId" description="">
                            <b-form-select v-model="topic.parentId">
                                <option value="0">- Scegli un padre -</option>
                                <option v-for="topic in orderedTopics" v-bind:value="topic.id" :key="topic.id">
                                    {{ '\xA0\xA0\xA0\xA0'.repeat(topic.hierarchyLevel-1)}}
                                    {{ topic.fullOrder }}
                                    {{ topic.title }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <b-form-group id="mandatory" label="" label-for="mandatory" description="">
                    <b-form-checkbox name="mandatory" value="true" v-model="topic.mandatory">Obbligatorio</b-form-checkbox>
                </b-form-group>

                <b-form-group id="idSim" label="ID dell'argomento per simulatore" label-for="idSim" description="">
                    <b-form-input v-model="topic.idSim"
                                  placeholder=""></b-form-input>
                </b-form-group>

            </b-form>
            <div class="action-bar row">
                <div class="col-sm-6">
                    <button class="btn btn-success " @click="saveTopic" :disabled="this.$store.state.loading"><b-icon icon="file-earmark-check"/> Salva</button>

                </div>
                <div class="col-sm-6 text-right">

                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>
</template>

<script>
import TopicDataService from "./TopicDataService";
import {required} from 'vuelidate/lib/validators'

export default {
    name: "topic-create",
    data() {
        return {
            topic: {
                id: null,
                idSim: null,
                title: null,
                order: null,
                mandatory: true,
                programId: null,
                parentId: null
            },
            orderedTopics: [],
            submitted: false,
            message: '',
            errorMsg: ''
        };
    },
    validations: {
        topic: {
            title: {
                required,
            },
            order: {
                required,
            }
        }
    },
    props: ["programId","topics"],
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.topic[name];
            return $dirty ? !$error : null;
        },
        saveTopic() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.submitted = false;
            } else {
                this.topic.programId = this.programId;
                TopicDataService.create(this.topic)
                    .then(response => {
                        this.topic.id = response.data.id;
                        this.submitted = true;
                        this.$emit('created-topic');
                    })
                    .catch(e => {
                        this.errorMsg = 'Non hai compilato tutti i campi';
                        console.log(e);
                    });
            }
        },

        closeEditor() {
            this.$emit('closed-editor');
        },
    },
    mounted() {
        function createTopicListArray(topics, resultArray, parentOrder){
            for (let topic of topics) {
                let fullOrder = (parentOrder > 0) ? parentOrder+'.'+topic.order : topic.order;
                resultArray.push({
                    id: topic.id,
                    title: topic.title,
                    order: topic.order,
                    fullOrder: fullOrder,
                    hierarchyLevel: topic.hierarchyLevel,
                });
                if (topic.children) createTopicListArray(topic.children, resultArray, fullOrder);
                else return;
            }
        }
        createTopicListArray(this.topics, this.orderedTopics, 0);
    }
};
</script>

<style>
.submit-form {
    max-width: 300px;
    margin: auto;
}
</style>
