import http from "../../helpers/http-common";

class DrivingLicenseDataService {
    getAll() {
        return http.get("/driving_licenses");
    }

    get(id) {
        return http.get(`/driving_licenses/${id}`);
    }

    create(data) {
        return http.post("/driving_licenses", data);
    }

    update(id, data) {
        return http.put(`/driving_licenses/${id}`, data);
    }

    archive(id) {
        return http.get(`/driving_licenses/archive/${id}`);
    }

    delete(id) {
        return http.delete(`/driving_licenses/${id}`);
    }

    deleteAll() {
        return http.delete('/driving_licenses');
    }

    findByName(name) {
        return http.get(`/driving_licenses?name=${name}`);
    }
}

export default new DrivingLicenseDataService();
